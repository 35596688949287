fieldset.fieldSet {
  border: 1px solid $border-colour-light;
  background-color: $fieldSet-bg-colour;
  flex-grow: 1;

  // width: MIN(100%, 600px);
}
fieldset legend {
  text-transform: uppercase;
  font-size: 1.6em;
  font-family: 'Bebas Neue', cursive;
  letter-spacing: 0.3em;
}
