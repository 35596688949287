@import '../../index.scss';

header {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  a {
    color: $main-bg-colour;
  }

  nav {
    height: clamp(1rem, $header-height, $header-height);
    background-color: $main-font-colour;
    color: $main-bg-colour;

    span {
      width: 100%;
      text-align: center;
    }

    .login-wrapper {
      display: flex;
      align-items: center;
    }
  }
  .nav-wrapper {
    border-right: 3px solid $main-font-colour;
    border-left: 3px solid $main-font-colour;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      a.active {
        color: $main-font-colour;
        background-color: $main-bg-colour;
        display: flex;
        justify-content: center;
      }
      &:hover {
        background: $border-colour-light;
      }
    }
  }
  .header-user {
    text-align: center;
    color: $warning;
    font-size: 0.8rem;
    font-weight: bold;
    margin: 0.2em 0 0.2em 0;

    .user-info-nav-link {
      border: 1px solid $border-colour-light;
      background-color: $main-font-colour;
      font-size: 0.8em;
      padding: 0.2em;
      color: $warning;
      .header-user-name {
        color: $link-colour;
        letter-spacing: 0.5rem;
      }
      &:hover {
        background-color: $main-bg-colour;
        border: 1px solid $border-colour-dark;
        color: $link-colour;
        transition: all 0.8s;
      }
    }
  }
}
/* Responsive layout */
@media (max-width: 812px) {
  header nav {
    font-size: 0.9em;
    padding: 6px 0;
  }
}
