@import '../../index.scss';

footer {
  height: $footer-height;
  font-size: 0.7em;
  border: 1px solid $border-colour;
  background-color: $main-font-colour;
  color: $main-bg-colour;
  padding: 4px 0;
  text-align: center;
  .footer-content-wrapper {
    display: flex;
    gap: 2rem;
    justify-content: space-around;
  }
}
