.upload-icon {
  color: $main-font-colour;
  opacity: 0.4;
  &:hover {
    cursor: pointer;
    opacity: 1;
    font-weight: bold;
    color: $info;
    transition: all 0.8s;
  }
}

.bullhorn-icon {
  color: $main-font-colour;
  opacity: 0.4;
  &:hover {
    cursor: pointer;
    opacity: 1;
    font-weight: bold;
    color: $info;
    transition: all 0.8s;
  }
}

.pencil-icon {
  color: $main-font-colour;
  opacity: 0.4;
  &:hover {
    cursor: pointer;
    opacity: 1;
    font-weight: bold;
    color: $warning;
    transition: all 0.8s;
  }
}

.trash-icon {
  color: $main-font-colour;
  opacity: 0.4;
  &:hover {
    cursor: pointer;
    opacity: 1;
    font-weight: bold;
    color: $danger;
    transition: all 0.8s;
  }
}

.sort-down-arrow-icon,
.sort-down-up-icon {
  color: $main-font-colour;
  opacity: 0.4;
  &:hover {
    cursor: pointer;
    opacity: 1;
    font-weight: bold;
    color: $info;
    transition: all 0.8s;
  }
}

.reg-thumbs-up-icon {
  color: $success;
}
.reg-thumbs-down-icon {
  color: $danger;
}
